import React from "react"
import Layout from "../components/layout";
import FeaturedBlog from "../components/featuredBlog";
import {graphql} from "gatsby";

const Blogs = ({data}) => (
    <Layout>
        <section className="blog py-5" id="blog-section">
            <div className="container pt-5">
                <div className="text-center pb-lg-4 pt-3">
                    <h2>Our latest articles</h2>
                </div>
                <div className="row">
                    <FeaturedBlog articles={data.allWpPost.nodes} showDesc={true}/>
                </div>
            </div>
        </section>
    </Layout>
);

export const pageQuery = graphql`
    query {
        allWpPost(sort: { fields: [date], order: DESC }, filter: {categories: {nodes: {elemMatch: {id: {eq: "dGVybTox"}}}}}) {
          nodes {
            title
            excerpt
            slug
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              node {  
                remoteFile {
                  childImageSharp {
                      fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid_tracedSVG
                      }
                    }
                }
              }  
            }
          }
        }
    }
`

export default Blogs